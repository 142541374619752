import React from 'react';
import { Typography, Box } from '@material-ui/core';
import Layout from '../../../components/layout';
function RSVPYESSuccess() {
  return (
    <Layout>
      <Box mt={10}>
        <Box my={5}>
          <Typography align='center' variant='body1' gutterBottom>
            Thank you for confirming your attendance to the #GMIS2021 Gala Dinner
          </Typography>
          <Typography align='center' variant='body1' gutterBottom>
            on November 22nd, 7PM at Ain Dubai, Bluewaters Island.
          </Typography>
        </Box>
        <Box mb={2}>
          <Typography variant='body1' align='center' gutterBottom>
            We look forward to seeing you.
          </Typography>
        </Box>
      </Box>
    </Layout>
  );
}
export default RSVPYESSuccess;
